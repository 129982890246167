import https from "./https"
import store from "@/store"

const shortUrlApi = {
  getShortUrls(organization, params) {
    return https.get(`admin/organizations/${organization.id}/short-urls`, {params})
  },
  getShortUrl(organization, ShortUrlId) {
    return https.get(`admin/organizations/${organization.id}/short-urls/${ShortUrlId}`)
  },
  updateShortUrl(organization, params) {
    return https.put(`admin/organizations/${organization.id}/short-urls/${params.id}`, params)
  },
  removeShortUrl(organization, params) {
    const url = 'admin/organizations/' + organization.id + '/short-urls/' + params.id;
    return https.delete(url)
  },
  storeShortUrl (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/short-urls'

    return https.post(url, params)
  }
}

export default shortUrlApi
